import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Layout({ children, gtMonetisationFeatureEnabled }) {
    return (
        <>
            <Header
                gtMonetisationFeatureEnabled={gtMonetisationFeatureEnabled}
            />
            <main className="guitartuna-root">{children}</main>
            <Footer />
        </>
    )
}

export default Layout
