import { useEffect, useReducer, useRef, useCallback } from 'react'
import CONFIG, { APPLICATION_NAME } from '../config/current'

const DEFAULT_ANALYTICS_CONFIGS = {
    profileId: '',
    config: {
        baseUrl: CONFIG.apiAddress,
        xApplicationName: APPLICATION_NAME,
    },
}

const CACHED_FEATURES_STORAGE_KEY = 'YS_FEATURES'
const YS_VISITOR_COOKIE_NAME = 'ys_visitor'

/**
 * Retrieves the value of a cookie by name.
 * @param {string} name - Name of the cookie to retrieve.
 * @returns {string | null} - The value of the cookie, or null if not found.
 */
const getCookie = (name) => {
    const cookies = document.cookie.split('; ')
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=')
        if (cookieName === name) {
            return decodeURIComponent(cookieValue)
        }
    }
    return null
}

/**
 * Retrieves the visitor ID from cookies.
 * @returns {string | null} - The visitor ID, or null if not found.
 */
const getVisitorId = () => getCookie(YS_VISITOR_COOKIE_NAME)

/**
 * Creates a query parameter string from an array of features for API requests.
 * @param {string[]} features - An array of feature names.
 * @returns {string} - The query parameter string.
 */
const createFeatureQueryParamString = (features) =>
    features
        .map((feature) => `&features=${encodeURIComponent(feature)}`)
        .join('')

/**
 * Retrieves cached features from local storage.
 * @returns {Object} - The cached features object.
 */
const getCachedFeatures = () => {
    const storedFeatures = sessionStorage.getItem(CACHED_FEATURES_STORAGE_KEY)
    if (!storedFeatures) {
        return {}
    }

    try {
        const parsedStorageFeatures = JSON.parse(storedFeatures)
        if (
            typeof parsedStorageFeatures === 'object' &&
            parsedStorageFeatures !== null
        ) {
            return parsedStorageFeatures
        }
    } catch (e) {
        console.error('Error parsing stored features', e)
    }
    return {}
}

const initialState = {
    cacheChecked: false,
    isLoading: false,
    error: undefined,
    data: undefined,
    initialDataFromCache: undefined,
    missingFeatureNames: [],
}

const fetchReducer = (state, action) => {
    switch (action.type) {
        case 'loading':
            return { ...state, isLoading: true }
        case 'cacheChecked':
            return {
                ...state,
                isLoading: false,
                cacheChecked: true,
                initialDataFromCache: action.payload,
                data: action.payload,
            }
        case 'setMissingFeatureNames':
            return {
                ...state,
                isLoading: false,
                missingFeatureNames: action.payload,
            }
        case 'fetched':
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                missingFeatureNames: [],
            }
        case 'error':
            return {
                ...initialState,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

const getYsAnalytics = () => {
    const analytics = typeof window !== 'undefined' ? window.ysAnalytics : null
    if (!analytics) {
        console.warn('ysAnalytics is not initialized on window')
        return DEFAULT_ANALYTICS_CONFIGS
    }
    return analytics
}

/**
 * Custom hook to manage feature flags, including fetching and caching.
 *
 * The hook operates in several orchestrated steps:
 * 1. `checkAndDispatchCachedFeatures`: On initial mount, loads cached features from local storage.
 * 2. `identifyAndDispatchMissingFeatures`: Determines and dispatches any feature flags not found in the cache.
 * 3. `fetchAndDispatchFeatureData`: Fetches missing feature flags from the server and updates the state.
 * 4. `setFeaturesCache`: Caches new data fetched from the server to local storage.
 *
 * These steps ensure feature flags are loaded efficiently, using cached data when available and fetching as needed.
 *
 * @param {string[]} featureNames - An array of feature names to manage.
 * @returns {{
 *   state: {
 *     cacheChecked: boolean,
 *     isLoading: boolean,
 *     error: Error | undefined,
 *     data: Object | undefined,
 *     initialDataFromCache: Object | undefined,
 *     missingFeatureNames: string[],
 *   },
 *   isFeatureEnabled: (featureName: string) => boolean | undefined,
 *   getFeature: (featureName: string) => Object | undefined,
 *   getFeatureVariant: (featureName: string) => string | undefined,
 * }}
 */
const useManageFeatureFlags = (featureNames = []) => {
    const isFetchCancelled = useRef(false)

    const [state, dispatch] = useReducer(fetchReducer, initialState)

    const checkAndDispatchCachedFeatures = () => {
        const cachedFeatures = getCachedFeatures()
        dispatch({ type: 'cacheChecked', payload: cachedFeatures })
    }

    const identifyAndDispatchMissingFeatures = () => {
        if (!Array.isArray(featureNames) || featureNames.length === 0) return
        if (!state.cacheChecked || !state.initialDataFromCache) return
        const cachedFeaturesSet = new Set(
            Object.keys(state.initialDataFromCache),
        )
        const missingFeatureNames = featureNames.filter(
            (featureName) => !cachedFeaturesSet.has(featureName),
        )

        if (missingFeatureNames.length > 0) {
            dispatch({
                type: 'setMissingFeatureNames',
                payload: missingFeatureNames,
            })
        }
    }

    const fetchAndDispatchFeatureData = () => {
        if (state.missingFeatureNames.length === 0) return
        isFetchCancelled.current = false

        const fetchFeatures = async () => {
            dispatch({ type: 'loading' })

            try {
                const analytics = getYsAnalytics()
                const visitor_id = getVisitorId()
                if (!visitor_id) {
                    console.warn('Visitor token not found. Skipping A/B tests.')
                    return
                }

                const query = createFeatureQueryParamString(
                    state.missingFeatureNames,
                )
                const url = `${analytics.config.baseUrl}/features/web?visitor_id=${visitor_id}${query}&project=web`
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'X-Application-Name': analytics.config.xApplicationName,
                    },
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok.')
                }

                const data = await response.json()
                const features = {
                    ...state.initialDataFromCache,
                    ...data.features,
                }
                dispatch({ type: 'fetched', payload: features })
            } catch (err) {
                if (isFetchCancelled.current) return
                dispatch({ type: 'error', payload: err })
            }
        }

        fetchFeatures()

        return () => {
            isFetchCancelled.current = true
        }
    }

    const setFeaturesCache = () => {
        if (!state.data) return
        try {
            sessionStorage.setItem(
                CACHED_FEATURES_STORAGE_KEY,
                JSON.stringify(state.data),
            )
        } catch (e) {
            console.error('Saving to local storage failed', e)
        }
    }

    useEffect(checkAndDispatchCachedFeatures, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(identifyAndDispatchMissingFeatures, [
        state.cacheChecked,
        state.initialDataFromCache,
    ])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchAndDispatchFeatureData, [state.missingFeatureNames])

    useEffect(setFeaturesCache, [state.data])

    const isFeatureEnabled = useCallback(
        (featureName) => state.data?.[featureName]?.active,
        [state.data],
    )

    const getFeature = useCallback((featureName) => state.data?.[featureName], [
        state.data,
    ])

    const getFeatureVariant = useCallback(
        (featureName) => {
            const feature = state.data?.[featureName]
            if (!feature) return false
            if (!feature.active) return feature.active
            return feature.variant
        },
        [state.data],
    )

    return { state, isFeatureEnabled, getFeature, getFeatureVariant }
}

export default useManageFeatureFlags
