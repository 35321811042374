import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import useManageFeatureFlags from './useManageFeatureFlags'

const getExpirationDate = (minutes) => {
    const currentDate = new Date()
    return new Date(
        currentDate.setTime(
            currentDate.getTime() + Number(minutes) * 60 * 1000,
        ),
    )
}

const useGradualRolloutCookie = (
    featureFlagName,
    cookiePlan = { enabled: 'always', disabled: 'never' },
) => {
    const { isFeatureEnabled } = useManageFeatureFlags([featureFlagName])
    const [isFeatureEnabledResult, setIsFeatureEnabledResult] = useState(false)

    useEffect(() => {
        const result = isFeatureEnabled(featureFlagName)
        setIsFeatureEnabledResult(result)

        const in1week = 7 * 24 * 60
        Cookies.set(
            featureFlagName,
            result ? cookiePlan.enabled : cookiePlan.disabled,
            {
                expires: getExpirationDate(in1week),
                path: '/',
                secure: false,
                sameSite: 'lax',
                domain: '.yousician.com',
            },
        )
    }, [featureFlagName, cookiePlan, isFeatureEnabled])

    return isFeatureEnabledResult
}

export default useGradualRolloutCookie
